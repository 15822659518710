/**
* Generated automatically at built-time (2025-01-02T06:50:42.274Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "mquvee-beerserver-beer-cooler",templateKey: "sites/71-f7d1729c-e0e6-414c-b5ba-730a4face1e7"};