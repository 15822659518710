/**
* Generated automatically at built-time (2025-01-02T06:50:42.319Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "mquvee-winestore-wine-cabinet",templateKey: "sites/71-517a5cc7-fa94-44cf-aff0-b7fa45ddcafc"};